
import {createEffect} from 'solid-js';
import {
    EngineConnectInfo,
    logDebug, OKX_CONNECT_ERROR_CODES,
    OKX_INJECT_WALLET, OKXConnectError,
    OkxwalletInjectConnector,
    SessionTypes,
    WalletInfo
} from "@okxconnect/core";
import {tonWidgetController as widgetController} from '../config/ton-widget-controller';
import {SingleWalletModalState} from '../../models/single-wallet-modal';
import {isInTMA, sendExpand} from '../../app/utils/tma-api';
import {OKXConnectUiError} from '../../errors';
import {checkIconnectorEnable, eqWalletName} from '../../app/utils/wallets';
import {ActionConfiguration, LoadableReady, WalletsModalCloseReason} from "../../models";
import {tonSingleWalletModalState} from "../config/ton-modals-state";
import {universalAppState} from "../../universal/config/universal-app.state";
import {tonAppState} from "../config/ton-app.state";
import {ConnectAdditionalRequest} from "@okxconnect/tonsdk";

interface SingleWalletModalManagerCreateOptions {
    getWalltsInfo: () => WalletInfo[]
}

/**
 * Manages the modal window state.
 */
export class TonSingleWalletModalManager {

    private consumers: Array<(state: SingleWalletModalState) => void> = [];
    private getWalltsInfo: () => WalletInfo[]

    public state: SingleWalletModalState = tonSingleWalletModalState();

    constructor(options: SingleWalletModalManagerCreateOptions) {
        this.getWalltsInfo = options.getWalltsInfo
        createEffect(() => {
            const state = tonSingleWalletModalState();
            this.state = state;
            this.consumers.forEach(consumer => consumer(state));
        });
    }

    /**
     * Opens the modal window with the specified wallet.
     * @param wallet - Wallet app name.
     * @throws OKXConnectUiError if the specified wallet is not found.
     */
    public async open(wallet: string): Promise<void> {
        const fetchedWalletsList = this.getWalltsInfo();
        const walletsList = fetchedWalletsList;

        const externalWallets = walletsList
        const externalWallet = externalWallets.find(walletInfo => eqWalletName(walletInfo, wallet));
        if (externalWallet) {
            return this.openSingleWalletModal(externalWallet);
        }

        const error = `Trying to open modal window with unknown wallet "${wallet}".`;
        throw new OKXConnectUiError(error);
    }

    /**
     * Closes the modal window.
     * @default 'action-cancelled'
     */
    public close(reason:WalletsModalCloseReason = 'action-cancelled'): void {
        widgetController.closeSingleWalletModal(reason);
    }

    /**
     * Subscribe to the modal window state changes, returns unsubscribe function.
     */
    public onStateChange(onChange: (state: SingleWalletModalState) => void): () => void {
        this.consumers.push(onChange);

        return () => {
            this.consumers = this.consumers.filter(consumer => consumer !== onChange);
        };
    }

    public async handleConnect(
        connectMethod: (actionConfiguration: ActionConfiguration | undefined) => Promise<string>,
        actionConfiguration: ActionConfiguration | undefined
    ): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            try {
                connectMethod(actionConfiguration).then((result) => {
                    resolve(result);
                    logDebug("TonSingleWalletModalManager  result >>>",JSON.stringify(result));

                }).catch((error) => {
                    reject(error);
                    logDebug("TonSingleWalletModalManager  error >>>",JSON.stringify(error));
                });
            } catch (error) {
                reject(error);
                logDebug("TonSingleWalletModalManager  catch error >>>",JSON.stringify(error));
            }
        });
    };


    /**
     * Opens the modal window to connect to a specified wallet, and waits when modal window is opened.
     */
    public async openSingleWalletModal(wallet: WalletInfo): Promise<void> {
        if (isInTMA()) {
            sendExpand();
        }

        checkIconnectorEnable(false)
        if (!tonAppState.walletConnector.some((connector) => connector.enable)) {
            throw new OKXConnectError(OKX_CONNECT_ERROR_CODES.CHAIN_NOT_SUPPORTED)
        }

        const result = new Promise<void>(resolve => {
            const unsubscribe = this.onStateChange(state => {
                const {status} = state;
                if (status === 'opened') {
                    unsubscribe();
                    resolve();
                }
            });
        });

        if (tonAppState.walletConnector.length == 1
            && tonAppState.walletConnector[0]?.getTag() === OKX_INJECT_WALLET
            && tonAppState.connectRequestParameters?.state !== "loading"
        ){
            let walletConnector = tonAppState.walletConnector[0] as OkxwalletInjectConnector
            const connector = tonAppState.connector;
            if (connector) {
                window.addEventListener("okx-ton-connect-connection-info-before-jump", (event) => {
                    const customEvent = event as CustomEvent;
                    logDebug("TON   :::", JSON.stringify(customEvent.detail))
                    if (customEvent.detail && "connectInfo" in customEvent.detail) {
                        walletConnector.openWallet(customEvent.detail.connectInfo)
                    }
                });

                let tonProof = (tonAppState.connectRequestParameters as LoadableReady<ConnectAdditionalRequest>
                )?.value.tonProof
                let request:any = {
                    openUniversalLink:false
                }
                if (tonProof) {
                    request.tonProof = tonProof;
                }
                await connector.connect(request)
            }
        }else{
            widgetController.openSingleWalletModal(wallet);
            return result
        }


    }
}
