import {
    Account, BridgeConnectionRequest,
    creatOKXMiniAppWalletInfo,
    EngineConnectInfo, Iconnector,
    creatOKXWalletInfo,
    logDebug, OKX_CONNECT_ERROR_CODES,
    OKXConnectError,
    WalletInfo
} from "@okxconnect/core";
import {
    ConnectAdditionalRequest,
    OKXITonConnect,
    OKXTonConnect,
    SendTransactionRequest,
    SendTransactionResponse,
    Wallet,
} from "@okxconnect/tonsdk";
import {tonWidgetController as widgetController} from '../ton/config/ton-widget-controller';
import {WalletInfoStorage} from '../storage';
import {createMacrotaskAsync, getSystemTheme, preloadImages, subscribeToThemeChange} from '../app/utils/web-api';
import {mergeOptions} from '../app/utils/options';
import {setTonAppState} from './config/ton-app.state';
import {unwrap} from 'solid-js/store';
import {
    ActionConfiguration,
    StrictActionConfiguration
} from '../models/action-configuration';
import {
    eqWalletName,
    getDefaultTonConnector,
    isAppWallet, isTgWallet,
    openTonWallet,
    openWallet,
    openWalletForUIRequest, showActionButton
} from '../app/utils/wallets';
import {uniq} from '../app/utils/array';
import {TransactionModalManager} from './managers/transaction-modal-manager';
import {isInTMA, sendExpand} from '../app/utils/tma-api';
import {SingleWalletModalState} from '../models/single-wallet-modal';
import {OKXTonConnectUITracker} from './tracker/okx-ton-connect-ui-tracker';
import {tonConnectUiVersion} from "../constants/version";
import {OKXConnectUiError} from '../errors';
import {
    Loadable,
    WalletInfoWithOpenMethod,
    WalletsModalCloseReason,
    WalletsModalState
} from '../models';
import {OKXConnectUI} from "../okx-connect-ui.interface";
import {TonSingleWalletModalManager} from "./managers/ton-single-wallet-modal-manager";
import {ConnectAdditionalParams, OKXConnectTonUiOptions, OKXTonConnectUiCreateOptions} from "./models";
import {isValidLocale} from "../models/locales";
import {ConnectedWallet} from "./models/wallet";
import { isDevice } from "../app/styles/media";
import { isMobile } from "../app/hooks/isMobile";
import { Action } from "../app/models/action-modal";
import { lastTonSelectedWalletInfo } from "./config/ton-modals-state";
import { setTonTheme } from "./theme/ton-theme-state";

export class OKXTonConnectUIInner implements OKXConnectUI{

    public static getWallets(): WalletInfo[] {
        return OKXTonConnect.getWallets();
    }
    private readonly walletInfoStorage = new WalletInfoStorage('okx-wallet-connect-ui-ton_wallet-info');

    /**
     * Emits user action event to the EventDispatcher. By default, it uses `window.dispatchEvent` for browser environment.
     * @private
     */
    private readonly tracker: OKXTonConnectUITracker;

    private walletInfo: WalletInfoWithOpenMethod | null = null;

    private systemThemeChangeUnsubscribe: (() => void) | null = null;

    private actionsConfiguration?: ActionConfiguration;

    private readonly walletsList: WalletInfo[]

    private connectRequestParametersCallback?: (
        parameters: ConnectAdditionalRequest | undefined
    ) => void;

    public readonly connector: OKXITonConnect;

    private readonly singleWalletModal: TonSingleWalletModalManager;

    private readonly transactionModal: TransactionModalManager;

    /**
     * Promise that resolves after end of th connection restoring process (promise will fire after `onStatusChange`,
     * so you can get actual information about wallet and session after when promise resolved).
     * Resolved value `true`/`false` indicates if the session was restored successfully.
     */
    public readonly connectionRestored = Promise.resolve(false);

    private defaultConnector = getDefaultTonConnector()

    /**
     * Current connection status.
     */
    public get connected(): boolean {
        return this.connector.connected;
    }

    /**
     * Current connected account or null.
     */
    public get account(): Account | null {
        return this.connector.account;
    }

    /**
     * Curren connected wallet app and its info or null.
     */
    public get wallet(): Wallet | (Wallet & WalletInfoWithOpenMethod) | null {
        if (!this.connector.wallet) {
            return null;
        }

        return {
            ...this.connector.wallet,
            ...this.walletInfo
        };
    }

    public get walletName():string | undefined{

        if (this.wallet && 'name' in this.wallet){
            return this.wallet.name
        }
        return undefined;
    }


    /**
     * Set and apply new UI options. Object with partial options should be passed. Passed options will be merged with current options.
     * @param options
     */
    public set uiOptions(options: OKXConnectTonUiOptions) {
        logDebug('OKXTonConnectUI uiOptions() called')
        this.checkButtonRootExist(options.buttonRootId);
   
        this.actionsConfiguration = options.actionsConfiguration;

        if (options.uiPreferences?.theme) {
            if (options.uiPreferences?.theme !== 'SYSTEM') {
                this.systemThemeChangeUnsubscribe?.();
                // setTonTheme(options.uiPreferences.theme, options.uiPreferences.colorsSet);
                setTonTheme(options.uiPreferences.theme);
            } else {
                // setTonTheme(getSystemTheme(), options.uiPreferences.colorsSet);
                setTonTheme(getSystemTheme());

                if (!this.systemThemeChangeUnsubscribe) {
                    this.systemThemeChangeUnsubscribe = subscribeToThemeChange(setTonTheme);
                }
            }
        } else {
            // if (options.uiPreferences?.colorsSet) {
            //     setColors(options.uiPreferences.colorsSet);
            // }
        }

        // if (options.uiPreferences?.borderRadius) {
        //     setBorderRadius(options.uiPreferences.borderRadius);
        // }

        setTonAppState(state => {
            const merged = mergeOptions(
                {
                    ...(options.language && { language: options.language }),
                    ...(!!options.actionsConfiguration?.returnStrategy && {
                        returnStrategy: options.actionsConfiguration.returnStrategy
                    }),
                    ...(options.actionsConfiguration?.tmaReturnUrl && {
                        tmaReturnUrl: options.actionsConfiguration.tmaReturnUrl
                    } )
                },
                unwrap(state)
            );

            if (options.buttonRootId !== undefined) {
                merged.buttonRootId = options.buttonRootId;
            }

            // if (options.enableAndroidBackHandler !== undefined) {
            //     merged.enableAndroidBackHandler = options.enableAndroidBackHandler;
            // }

            return merged;
        });
    }


    constructor(options?: OKXTonConnectUiCreateOptions) {
        logDebug('OKXTonConnectUI constructor() option:',JSON.stringify(options));

        if (options && 'dappMetaData' in options && options.dappMetaData) {
            this.connector = new OKXTonConnect({
                metaData: options.dappMetaData,
                connectors: this.defaultConnector
            })
        } else {
            throw new OKXConnectUiError(
                'You have to specify a `dappMetaData`in the options.'
            );
        }
        if (options.language && !isValidLocale(options.language.toString())){
            options.language = "en_US"
        }

        this.tracker = new OKXTonConnectUITracker({
            eventDispatcher: null,
            tonConnectUiVersion: tonConnectUiVersion
        });

        this.singleWalletModal = new TonSingleWalletModalManager({
            getWalltsInfo: () => OKXTonConnect.getWallets()
        });
        this.transactionModal = new TransactionModalManager();

        this.walletsList = this.getWallets();
        preloadImages(uniq(this.walletsList.map(item => item.imageUrl)));

        // const rootId = this.normalizeWidgetRoot(options?.widgetRootId);
        const rootId = this.normalizeWidgetRoot(undefined);

        this.subscribeToWalletChange();

        if (options?.restoreConnection !== false) {
            this.connectionRestored = createMacrotaskAsync(async () => {
                this.tracker.trackConnectionRestoringStarted();
                await this.connector.restoreConnection();

                if (!this.connector.connected) {
                    this.tracker.trackConnectionRestoringError('Connection was not restored');
                    this.walletInfoStorage.removeWalletInfo();
                } else {
                    this.tracker.trackConnectionRestoringCompleted(this.wallet);
                }

                return this.connector.connected;
            });
        }

        this.uiOptions = mergeOptions(options, { uiPreferences: { theme: 'SYSTEM' } });
        setTonAppState({
            connector: this.connector,
            preferredWalletAppName: undefined,
            walletConnector: this.defaultConnector,
            dappMetaData:options.dappMetaData
        });

        this.dispose = widgetController.renderApp(rootId, this);
    }
    private dispose:()=>void
    public destory(){
        if (this.dispose){
            this.dispose()
        }
    }

    /**
     * Use it to customize ConnectRequest and add `tonProof` payload.
     * You can call it multiply times to set updated tonProof payload if previous one is outdated.
     * If `connectRequestParameters.state === 'loading'` loader will appear instead of the qr code in the wallets modal.
     * If `connectRequestParameters.state` was changed to 'ready' or it's value has been changed, QR will be re-rendered.
     */
    public setConnectRequestParameters(
        connectRequestParameters: Loadable<ConnectAdditionalParams> | undefined | null
    ): void {
        setTonAppState({ connectRequestParameters });
        if (connectRequestParameters?.state === 'ready' || !connectRequestParameters) {
            this.connectRequestParametersCallback?.({
                tonProof: connectRequestParameters!.value.tonProof
            });
        }
    }

    /**
     * Returns available wallets list.
     */
    public getWallets(): WalletInfo[] {
        return [creatOKXWalletInfo(),creatOKXMiniAppWalletInfo()];
    }

    /**
     * Subscribe to connection status change.
     * @return function which has to be called to unsubscribe.
     */
    public onStatusChange(
        callback: (wallet: ConnectedWallet | null) => void,
        errorsHandler?: (err: OKXConnectError) => void
    ): ReturnType<OKXITonConnect['onStatusChange']> {
        return this.connector.onStatusChange(async wallet => {
            if (wallet) {
                const lastSelectedWalletInfo = await this.getSelectedWalletInfo(wallet);
               let info = this.getWalletInfo(wallet);
                logDebug("OKXTonConnectUI onStatusChange info :",JSON.stringify(info))
                logDebug("OKXTonConnectUI onStatusChange wallet :",JSON.stringify(wallet))

               callback({
                    ...wallet,
                    ...(info || this.getWallets()[0])!
                });
            } else {
                callback(wallet);
            }
        }, errorsHandler);
    }

    public async handleConnect(connectMethod: (actionConfiguration:ActionConfiguration | undefined) => Promise<string>): Promise<string> {
      return this.singleWalletModal.handleConnect(connectMethod, this.actionsConfiguration);
    };

    /**
     * Opens the modal window, returns a promise that resolves after the modal window is opened.
     */
    public async openModal(): Promise<void> {
        logDebug('OKXTonConnectUI openModal() called')
        return new Promise<void>(async (_, reject) => {
            await Promise.all(
                [this.waitForWalletConnection({}),
                this.openSingleWalletModal("okxAppWallet")]
            ).catch((error) => {
                reject(error);
            })
        })
    }

    /**
     * Closes the modal window.
     */
    public closeModal(reason?: WalletsModalCloseReason): void {
        this.closeSingleWalletModal(reason);
    }

    private async waitForWalletConnection(
        options: WaitWalletConnectionOptions
    ): Promise<ConnectedWallet> {
        return new Promise<ConnectedWallet>((resolve, reject) => {
            const { ignoreErrors = false, signal = null } = options;

            const onStatusChangeHandler = async (wallet: ConnectedWallet | null): Promise<void> => {
                if (!wallet) {
                    this.tracker.trackConnectionError('Connection was cancelled');
                    unsubscribe();
                    reject(new OKXConnectUiError('Wallet was not connected'));
                } else {
                    this.tracker.trackConnectionCompleted(wallet);
                    unsubscribe();
                    resolve(wallet);
                }
            };

            const onErrorsHandler = (reason: OKXConnectError): void => {
                this.tracker.trackConnectionError(reason.message);
                unsubscribe();
                reject(reason);
            };

            const unsubscribe = this.onStatusChange(
                (wallet: ConnectedWallet | null) => onStatusChangeHandler(wallet),
                (reason: OKXConnectError) => onErrorsHandler(reason)
            );
        });
    }

    /**
     * Subscribe to the modal window state changes, returns a function which has to be called to unsubscribe.
     */
    public onModalStateChange(onChange: (state: WalletsModalState) => void): () => void {
        return this.onSingleWalletModalStateChange(onChange)
    }

    /**
     * Returns current modal window state.
     */
    public get modalState(): WalletsModalState {
        return this.singleWalletModalState;
    }


    private async openSingleWalletModal(wallet: string): Promise<void> {
        this.tracker.trackConnectionStarted();
        try {
            return this.singleWalletModal.open(wallet);
        }catch (error) {
            this.tracker.trackConnectionError(error.message);
            throw error;
        }
    }

    private closeSingleWalletModal(closeReason?: WalletsModalCloseReason): void {
        if (closeReason === 'action-cancelled') {
            this.tracker.trackConnectionError('Connection was cancelled');
        }
        this.singleWalletModal.close(closeReason);
    }


    private onSingleWalletModalStateChange(
        onChange: (state: SingleWalletModalState) => void
    ): () => void {
        return this.singleWalletModal.onStateChange(onChange);
    }


    private get singleWalletModalState(): SingleWalletModalState {
        return this.singleWalletModal.state;
    }

    /**
     * Disconnect wallet and clean localstorage.
     */
    public disconnect(): Promise<void> {
        logDebug('OKXTonConnectUI disconnect() called')
        this.tracker.trackDisconnection(this.wallet, 'dapp');

        widgetController.clearAction();
        widgetController.removeSelectedWalletInfo();
        this.walletInfoStorage.removeWalletInfo();
        return this.connector.disconnect();
    }

    public async sendTransaction(
        tx: SendTransactionRequest,
        actionConfiguration?: ActionConfiguration
    ): Promise<SendTransactionResponse> {
        logDebug('OKXTonConnectUI sendTransaction() called')
        return this.sendTransactionInner(tx,
            {
                returnStrategy: actionConfiguration?.returnStrategy,
                modals: actionConfiguration?.modals
            })
    }

    /**
     * Opens the modal window and handles the transaction sending.
     * @param tx transaction to send.
     * @param options modal behaviour settings
     */
    private async sendTransactionInner(
        tx: SendTransactionRequest,
        actionConfiguration?: ActionConfiguration
    ): Promise<SendTransactionResponse> {
        this.tracker.trackTransactionSentForSignature(this.wallet, tx);

        if (!this.connected) {
            this.tracker.trackTransactionSigningFailed(this.wallet, tx, 'Wallet was not connected');
            throw new OKXConnectUiError('Connect wallet to send a transaction.');
        }

        if (isInTMA()) {
            sendExpand();
        }

        const options: ActionConfiguration = {
            returnStrategy: (
                isDevice("mobile") &&
                lastTonSelectedWalletInfo()?.openMethod !== 'qrcode'
            ) ? actionConfiguration?.returnStrategy : "none",
            modals: actionConfiguration?.modals,
            tmaReturnUrl: actionConfiguration?.tmaReturnUrl ?? "back",
        };

        // const { notifications, modals, returnStrategy, twaReturnUrl } =
        //     this.getModalsAndNotificationsConfiguration(options);
        const { modals, returnStrategy,tmaReturnUrl } =
            this.getModalsAndNotificationsConfiguration(options);

        widgetController.setAction({
            name: 'confirm-transaction',
            // showNotification: false,
            openModal: modals.includes('before'),
            sent: false
        });

        const onRequestSent = (): void => {
            if (abortController.signal.aborted) {
                return;
            }

            // openWallet(this.wallet(),tmaReturnUrl)

            if (!(this.connector as OKXTonConnect).openUniversalLink  && openWalletForUIRequest(this.walletInfo, lastTonSelectedWalletInfo()?.openMethod)){
                openTonWallet(this.walletInfo,tmaReturnUrl)
            }

            widgetController.setAction({
                name: 'confirm-transaction',
                // showNotification: false,
                openModal: modals.includes('before'),
                sent: true
            });
        };

        const abortController = new AbortController();

        const unsubscribe = this.onTransactionModalStateChange(action => {
            if (action?.openModal) {
                return;
            }

            unsubscribe();
            // if (!action) {
            //     abortController.abort();
            // }
        });
        const transaction = {...tx, redirect: returnStrategy};
        try {
            const result = await this.waitForSendTransaction(
                {
                    transaction: transaction,
                    signal: abortController.signal
                },
                onRequestSent
            );

            this.tracker.trackTransactionSigned(this.wallet, tx, result);

            widgetController.setAction({
                name: 'transaction-sent',
                // showNotification: false,
                openModal: modals.includes('success')
            });

            return result;
        } catch (e) {


            if (e instanceof OKXConnectError) {

                if (e.code === OKX_CONNECT_ERROR_CODES.USER_REJECTS_ERROR){
                    widgetController.setAction({
                        name: 'transaction-canceled',
                        openModal: modals.includes('error')
                    });
                }else{
                    widgetController.setAction({
                        name: 'transaction-error',
                        openModal: modals.includes('error')
                    });
                }
                throw e;
            } else {
                widgetController.setAction({
                    name: 'transaction-error',
                    openModal: modals.includes('error')
                });
                throw new OKXConnectUiError('Unhandled error:' + e);
            }
        } finally {
            unsubscribe();
        }
    }

    private async waitForSendTransaction(
        options: WaitSendTransactionOptions,
        onRequestSent?: () => void
    ): Promise<SendTransactionResponse> {
        return new Promise<SendTransactionResponse>((resolve, reject) => {
            const { transaction, signal } = options;

            if (signal.aborted) {
                this.tracker.trackTransactionSigningFailed(
                    this.wallet,
                    transaction,
                    'Transaction was cancelled'
                );
                return reject(new OKXConnectUiError('Transaction was not sent'));
            }

            const onTransactionHandler = async (
                transaction: SendTransactionResponse
            ): Promise<void> => {
                resolve(transaction);
            };

            const onErrorsHandler = (reason: OKXConnectError): void => {
                reject(reason);
            };

            const onCanceledHandler = (): void => {
                this.tracker.trackTransactionSigningFailed(
                    this.wallet,
                    transaction,
                    'Transaction was cancelled'
                );
                reject(new OKXConnectUiError('Transaction was not sent'));
            };

            signal.addEventListener('abort', onCanceledHandler, { once: true });

            this.connector
                .sendTransaction(transaction, {
                    doNotOpenWallet: !(isMobile() && lastTonSelectedWalletInfo()?.openMethod !== 'qrcode'),
                    onRequestSent: onRequestSent
                })
                .then(result => {
                    signal.removeEventListener('abort', onCanceledHandler);
                    return onTransactionHandler(result);
                })
                .catch(reason => {
                    this.tracker.trackTransactionSigningFailed(
                        this.wallet,
                        transaction,
                        reason.message
                    );
                    signal.removeEventListener('abort', onCanceledHandler);
                    return onErrorsHandler(reason);
                });
        });
    }

    /**
     * Subscribe to the transaction modal window state changes, returns a function which has to be called to unsubscribe.
     * @internal
     */
    private onTransactionModalStateChange(onChange: (action: Action | null) => void): () => void {
        return this.transactionModal.onStateChange(onChange);
    }

    private subscribeToWalletChange(): void {
        this.connector.onStatusChange(async wallet => {
            if (wallet) {
                await this.updateWalletInfo(wallet);
                this.setPreferredWalletAppName(this.walletInfo?.appName || wallet.device.appName);
            } else {
                this.walletInfoStorage.removeWalletInfo();
            }
        });
    }

    private setPreferredWalletAppName(value: string): void {
        setTonAppState({ preferredWalletAppName: value });
    }

    private async getSelectedWalletInfo(wallet: Wallet): Promise<WalletInfoWithOpenMethod | null> {
        let lastSelectedWalletInfo = widgetController.getSelectedWalletInfo();

        if (!lastSelectedWalletInfo) {
            return null;
        }

        let fullLastSelectedWalletInfo: WalletInfoWithOpenMethod;
        if (!('name' in lastSelectedWalletInfo)) {
            const walletsList = this.walletsList;
            const walletInfo = walletsList.find(item => eqWalletName(item, wallet.device.appName));

            if (!walletInfo) {
                throw new OKXConnectUiError(
                    `Cannot find WalletInfo for the '${wallet.device.appName}' wallet`
                );
            }

            fullLastSelectedWalletInfo = {
                ...walletInfo,
                ...lastSelectedWalletInfo
            };
        } else {
            fullLastSelectedWalletInfo = lastSelectedWalletInfo;
        }

        return fullLastSelectedWalletInfo;
    }

    private async updateWalletInfo(wallet: Wallet): Promise<void> {
        this.walletInfo = this.getWalletInfo(wallet)
        logDebug("updateWalletInfo ---this.walletInfo -",JSON.stringify(this.walletInfo))
    }

    private  getWalletInfo(wallet: Wallet):WalletInfo | null {
        logDebug("getWalletInfo ----",JSON.stringify(wallet))
        let info =  this.walletsList.find(walletInfo =>
            eqWalletName(walletInfo, wallet.device.appName)
        ) || null;
        logDebug("getWalletInfo ---info -",JSON.stringify(info))
        return info;
    }

    private normalizeWidgetRoot(rootId: string | undefined): string {
        if (!rootId || !document.getElementById(rootId)) {
            rootId = `okxton-widget-root`;
            const rootElement = document.createElement('div');
            rootElement.id = rootId;
            document.body.appendChild(rootElement);
        }

        return rootId;
    }

    private checkButtonRootExist(buttonRootId: string | null | undefined): void | never {
        if (buttonRootId == null) {
            return;
        }

        if (!document.getElementById(buttonRootId)) {
            throw new OKXConnectUiError(`${buttonRootId} element not found in the document.`);
        }
    }

    private getModalsAndNotificationsConfiguration(
        options?: ActionConfiguration
    ): StrictActionConfiguration {
        const allActions: StrictActionConfiguration['modals'] = [
            'before',
            'success',
            'error'
        ];
        //
        // let notifications: StrictActionConfiguration['notifications'] = allActions;
        // if (
        //     this.actionsConfiguration?.notifications &&
        //     this.actionsConfiguration?.notifications !== 'all'
        // ) {
        //     notifications = this.actionsConfiguration.notifications;
        // }
        //
        // if (options?.notifications) {
        //     if (options.notifications === 'all') {
        //         notifications = allActions;
        //     } else {
        //         notifications = options.notifications;
        //     }
        // }

        let modals: StrictActionConfiguration['modals'] = ['before'];
        if (this.actionsConfiguration?.modals) {
            if (this.actionsConfiguration.modals === 'all') {
                modals = allActions;
            } else {
                modals = this.actionsConfiguration.modals;
            }
        }
        if (options?.modals) {
            if (options.modals === 'all') {
                modals = allActions;
            } else {
                modals = options.modals;
            }
        }

        const returnStrategy =
            options?.returnStrategy || this.actionsConfiguration?.returnStrategy || 'back';

        const tmaReturnUrl =
            options?.tmaReturnUrl || this.actionsConfiguration?.tmaReturnUrl || 'back';

        // let skipRedirectToWallet =
        //     options?.skipRedirectToWallet ||
        //     this.actionsConfiguration?.skipRedirectToWallet ||
        //     'ios';
        //
        // // TODO: refactor this check after testing
        // if (isInTMA()) {
        //     skipRedirectToWallet = 'never';
        // }

        return {
            modals,
            tmaReturnUrl,
            returnStrategy,
        };
    }

    showActionButton(): boolean {
        return showActionButton(this.walletInfo)
    }

    openWallet(connectionRequest?: EngineConnectInfo, connector?: Iconnector) {
        (this.connector as OKXTonConnect).provider?.connectorOpenWallet()
    }

}

type WaitWalletConnectionOptions = {
    ignoreErrors?: boolean;
    signal?: AbortSignal | null;
};

type WaitSendTransactionOptions = {
    transaction: SendTransactionRequest;
    signal: AbortSignal;
};
