import {OKXConnectUI} from "../okx-connect-ui.interface";
import {
    Account,
    BridgeConnectionRequest,
    EngineConnectInfo,
    Iconnector,
    logDebug,
    OKXConnectError,
    WalletInfo
} from "@okxconnect/core";
import {
    OKXITonConnect,
    OKXTonConnect,
    SendTransactionRequest,
    SendTransactionResponse,
    Wallet
} from "@okxconnect/tonsdk";
import {
    ActionConfiguration,
    Loadable,
    WalletInfoWithOpenMethod,
    WalletsModalCloseReason,
    WalletsModalState
} from "../models";
import {ConnectAdditionalParams, ConnectedWallet, OKXConnectTonUiOptions, OKXTonConnectUiCreateOptions} from "./models";
import {OKXTonConnectUIInner} from "./okx-connect-ton-ui";

export class OKXTonConnectUI implements OKXConnectUI {

    public static getWallets(): WalletInfo[] {
        return OKXTonConnectUIInner.getWallets();
    }

    private okxTonConnectUI: OKXTonConnectUIInner

    public readonly connectionRestored = Promise.resolve(false);

    /**
     * Current connection status.
     */
    public get connected(): boolean {
        return this.okxTonConnectUI.connected;
    }

    /**
     * Current connected account or null.
     */
    public get account(): Account | null {
        return this.okxTonConnectUI.account;
    }

    /**
     * Curren connected wallet app and its info or null.
     */
    public get wallet(): Wallet | (Wallet & WalletInfoWithOpenMethod) | null {
        return this.okxTonConnectUI.wallet
    }

    public get walletName(): string | undefined {
        return this.okxTonConnectUI.walletName
    }


    public set uiOptions(options: OKXConnectTonUiOptions) {
        this.okxTonConnectUI.uiOptions = options
    }

    constructor(options?: OKXTonConnectUiCreateOptions) {
        logDebug('okx-connect-ton-ui-wrapper constructor called')
        if (window && (window as any).okxConnectTonUIinwindow && (window as any).okxConnectTonUIinwindow instanceof OKXTonConnectUIInner){
            this.okxTonConnectUI = (window as any).okxConnectTonUIinwindow
            logDebug('okx-connect-ton-ui-wrapper constructor old called')
        }else{
            this.okxTonConnectUI = new OKXTonConnectUIInner(options)
            logDebug('okx-connect-ton-ui-wrapper constructor new called')
            if (window){
                (window as any).okxConnectTonUIinwindow = this.okxTonConnectUI
            }
        }
    }

    public setConnectRequestParameters(
        connectRequestParameters: Loadable<ConnectAdditionalParams> | undefined | null
    ): void {
        this.okxTonConnectUI.setConnectRequestParameters(connectRequestParameters)
    }

    /**
     * Returns available wallets list.
     */
    public getWallets(): WalletInfo[] {
        return this.okxTonConnectUI.getWallets()
    }

    /**
     * Subscribe to connection status change.
     * @return function which has to be called to unsubscribe.
     */
    public onStatusChange(
        callback: (wallet: ConnectedWallet | null) => void,
        errorsHandler?: (err: OKXConnectError) => void
    ): ReturnType<OKXITonConnect['onStatusChange']> {
        return this.okxTonConnectUI.onStatusChange(callback, errorsHandler)
    }

    public async handleConnect(connectMethod: (actionConfiguration: ActionConfiguration | undefined) => Promise<string>): Promise<string> {
        return this.okxTonConnectUI.handleConnect(connectMethod)
    };

    /**
     * Opens the modal window, returns a promise that resolves after the modal window is opened.
     */
    public async openModal(): Promise<void> {
        return this.okxTonConnectUI.openModal()
    }

    /**
     * Closes the modal window.
     */
    public closeModal(reason?: WalletsModalCloseReason): void {
        return this.okxTonConnectUI.closeModal(reason)
    }

    /**
     * Subscribe to the modal window state changes, returns a function which has to be called to unsubscribe.
     */
    public onModalStateChange(onChange: (state: WalletsModalState) => void): () => void {
        return this.okxTonConnectUI.onModalStateChange(onChange)
    }

    /**
     * Returns current modal window state.
     */
    public get modalState(): WalletsModalState {
        return this.okxTonConnectUI.modalState
    }

    /**
     * Disconnect wallet and clean localstorage.
     */
    public disconnect(): Promise<void> {
        return this.okxTonConnectUI.disconnect()
    }

    public async sendTransaction(
        tx: SendTransactionRequest,
        actionConfiguration?: ActionConfiguration
    ): Promise<SendTransactionResponse> {
        return this.okxTonConnectUI.sendTransaction(tx, actionConfiguration)
    }

    showActionButton(): boolean {
        return this.okxTonConnectUI.showActionButton()
    }

    openWallet(connectionRequest?: EngineConnectInfo, connector?: Iconnector) {
        this.okxTonConnectUI.openWallet(connectionRequest,connector)
    }

}